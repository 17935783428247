import React from "react"

import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"

import Button from "../components/button"
import MainBanner from "../components/main-banner"
import ConceptSection from "../components/concept-section"

import DinosaurIcon from "../assets/icons/icons-1x/dinosaur.svg"
import IdeaIcon from "../assets/icons/icons-1x/idea.svg"
import PlanetIcon from "../assets/icons/icons-1x/planet.svg"
import EstacionMeiquerLogo from "../assets/logos/logo-meiquer.svg"
import MeiquerAguilasLogo from "../assets/logos/logo-meiquer-aguilas.png"
import MeiquerJocotanLogo from "../assets/logos/logo-meiquer-jocotan.png"
import MeiquerLaurelesLogo from "../assets/logos/logo-meiquer-laureles.png"
import MeiquerOcotanLogo from "../assets/logos/logo-meiquer-ocotan.png"
import LuciernagaLogo from "../assets/allies/luciernaga@2x.png"
import GobLogo from "../assets/allies/gobierno-zapopan@2x.png"

const EstacionesJuegoPage = props => {
  const data = useStaticQuery(graphql`
    query EstaionesJuegoQuery {
      meiquerOrgImg: file(relativePath: { eq: "meiquer-org-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      meiquerJocotanImg: file(relativePath: { eq: "meiquer-jocotan-03.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const mainBannerContent = (
    <>
      <img
        style={{ width: "7em" }}
        src={EstacionMeiquerLogo}
        alt="Estación Meiquer Logo"
      />
      <p>
        Ambientes seguros de <span className="c-turquoise w-bold">juego</span>{" "}
        <span className="w-bold">creativo</span> para niñas y niños en espacios
        públicos para <span className="w-bold">crear comunidades</span>{" "}
        imaginativas, libres y transformadoras.
      </p>
      <div className="columns">
        <div className="column pl-0">
          <img src={GobLogo} alt="Logo Gobierno Zapopan" />
        </div>
        <div className="column">
          <img src={LuciernagaLogo} alt="Logo Luciernaga" />
        </div>
      </div>
    </>
  )

  const reasons = [
    {
      top: (
        <img style={{ height: "10rem" }} src={PlanetIcon} alt="Ícono Planeta" />
      ),
      content: (
        <>
          <h3>Ambiente seguro</h3>
          <p>
            Reactivamos espacios públicos para realizar actividades de juego
            creativo en comunidad.
          </p>
        </>
      ),
    },
    {
      top: <img style={{ height: "10rem" }} src={IdeaIcon} alt="Ícono Idea" />,
      content: (
        <>
          <h3>Agentes de cambio</h3>
          <p>
            Capacitamos agentes de cambio con nuestra Metodología Meiquer para
            llegar a más comunidades.
          </p>
        </>
      ),
    },
    {
      top: (
        <img
          style={{ height: "10rem" }}
          src={DinosaurIcon}
          alt="Ícono Dinosaurio"
        />
      ),
      content: (
        <>
          <h3>Empoderamiento</h3>
          <p>
            Las niñas y los niños desarrollan habilidades para vivir un proceso
            de empoderamiento.
          </p>
        </>
      ),
    },
  ]

  const reasonsTitle = (
    <h2 className="w-normal text-right">
      ¿Cómo <span className="w-bold">transformamos</span> comunidades{" "}
      <span className="c-turquoise w-bold">jugando</span>?
    </h2>
  )

  const infoBannerContent = (
    <>
      <h2 className="w-normal">
        <span className="c-turquoise w-bold">Juega</span> y{" "}
        <span className="w-bold">crea</span> en un{" "}
        <span className="s-italic">laboratorio creativo</span>
      </h2>
      <p>
        Nuestros programas son <span className="w-bold">experiencias</span> que{" "}
        <span className="w-bold">inspiran</span> a las niñas y niños a imaginar
        y crear en un laboratorio para aprender experimentando.
      </p>
      <div className="columns">
        <div className="column is-two-thirds pl-0">
          <Button>
            <span>Ver Fotos</span>
          </Button>
        </div>
      </div>
    </>
  )

  const services = [
    {
      top: (
        <img
          style={{ width: "6em" }}
          src={MeiquerJocotanLogo}
          alt="Estación Jocotán Logo"
        />
      ),
      content: (
        <p>
          Talleres en sábados de <span className="w-bold">10:00-13:00 hrs</span>{" "}
          en la Biblioteca Pública de Jocotán
        </p>
      ),
      bottom: (
        <Button href="https://goo.gl/maps/bbfnjJqWWQ3VT8QW9">
          Ver Ubicación
        </Button>
      ),
    },
    {
      top: (
        <img
          style={{ width: "6em" }}
          src={MeiquerLaurelesLogo}
          alt="Estación Laureles Logo"
        />
      ),
      content: (
        <p>
          Talleres en sábados de <span className="w-bold">9:00-12:30 hrs</span>{" "}
          en la Biblioteca Pública de FOVISSSTE
        </p>
      ),
      bottom: (
        <Button href="https://goo.gl/maps/L6jPFEQTNXLe4AcJ8">
          Ver Ubicación
        </Button>
      ),
    },
    {
      top: (
        <img
          style={{ width: "6em" }}
          src={MeiquerAguilasLogo}
          alt="Estación Águilas Logo"
        />
      ),
      content: (
        <p>
          Talleres en sábados de <span className="w-bold">10:00-13:00 hrs</span>{" "}
          en la Biblioteca Pública de las Águilas
        </p>
      ),
      bottom: (
        <Button href="https://goo.gl/maps/4rXBsuCPUa8xPwSu9">
          Ver Ubicación
        </Button>
      ),
    },
    {
      top: (
        <img
          style={{ width: "6em" }}
          src={MeiquerOcotanLogo}
          alt="Estación Ocotán Logo"
        />
      ),
      content: (
        <p>
          Talleres en sábados de <span className="w-bold">10:00-13:00 hrs</span>{" "}
          en la Colmena de San Juan de Ocotán
        </p>
      ),
      bottom: (
        <Button href="https://goo.gl/maps/4jrDLVbAWHnvJ3Q26">
          Ver Ubicación
        </Button>
      ),
    },
  ]

  const servicesTitle = (
    <h2 className="w-normal">
      ¿Quieres <span className="c-turquoise w-bold">jugar</span> y{" "}
      <span className="s-italic">compartir</span> en{" "}
      <span className="w-bold">comunidad</span>?
    </h2>
  )

  return (
    <Layout location={props.location}>
      <SEO title="Estaciones de Juego" />
      <MainBanner
        image={data.meiquerOrgImg.childImageSharp.fluid}
        content={mainBannerContent}
      />
      <ConceptSection concepts={reasons} title={reasonsTitle} />
      <MainBanner
        image={data.meiquerJocotanImg.childImageSharp.fluid}
        content={infoBannerContent}
        fromBottom={false}
      />
      <ConceptSection concepts={services} title={servicesTitle} />
    </Layout>
  )
}

export default EstacionesJuegoPage
